import React, { FC } from "react";
import { ContentUiContext } from "../Helpers/UiImageAndContentTab";

export const ServicesList: FC<{ servicesLists: any }> = ({ servicesLists }) => {
  return servicesLists?.map(
    ({
      id,
      serviceCard: {
        uiPlacement,
        featureTitle,
        featureShortDescription,
        quote,
        featuresList,
        featureImage: {
          file: { url: featureImage },
        },
        featureIcon: {
          file: { url: featureIcon },
        },
        redirectButtons,
      },
    }) => {
      let redirectsBtns = [];
      if (redirectButtons) {
        redirectsBtns = redirectButtons?.map(({ id, buttonName, buttonColor, path }) => {
          return {
            path,
            colour: buttonColor,
            title: buttonName,
            id,
          };
        });
      }
      return (
        <ContentUiContext
          key={id}
          title={featureTitle}
          shortDescription={featureShortDescription}
          imageURL={featureImage}
          quote={quote}
          imageDirection={uiPlacement[0] === "Right Image and Left Content" ? "right" : "left"}
          contextURL={featureIcon}
          contentList={featuresList}
          buttons={redirectsBtns}
        />
      );
    },
  );
};
