import SEO from "@/components/SEO";
import ServicesPage from "@/components/ServicesPage";
import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { FC, Fragment } from "react";

const Services: FC<PageProps> = () => (
  <StaticQuery
    query={graphql`
      query ServicePage {
        contentfulServicesPage {
          #     SEO
          seo {
            documentTitle
            metaTitle
            metaUrl
            metaAuthor
            description {
              description
            }
            metaKeywords
          }

          servicesLists {
            id
            slug
            serviceCard {
              uiPlacement
              featureTitle
              featureShortDescription
              featuresList
              featureIcon {
                file {
                  url
                }
              }
              featureImage {
                file {
                  url
                }
              }
              redirectButtons {
                id
                path
                buttonName
                buttonColor
              }
            }
          }

          #     Footer
          footerContent {
            content {
              raw
            }
          }
        }
      }
    `}
    render={({
      contentfulServicesPage: {
        seo: {
          documentTitle,
          metaTitle,
          metaUrl,
          description: { description },
          metaKeywords,
          metaAuthor,
        },
        footerContent,
        servicesLists,
      },
    }) => {
      return (
        <Fragment>
          <SEO
            contentfulSeo={{
              documentTitle,
              metaTitle,
              metaDescription: description,
              metaKeywords,
              metaUrl,
              metaAuthor: metaAuthor,
            }}
          />
          <ServicesPage footerContent={footerContent} servicesLists={servicesLists} />
        </Fragment>
      );
    }}
  />
);

export default Services;
