import React, { Component } from "react";
import Layout from "../Layout";
import { BreadCrumb } from "../Shared/BreadCrumb";
import { ServicesList } from "./ServicesList";

interface ServicePageProps {
  footerContent: any;
  servicesLists: any;
}

export default class ServicesPage extends Component<ServicePageProps> {
  render(): JSX.Element {
    const { footerContent, servicesLists } = this.props;

    return (
      <Layout contentfulFooterContentForPages={footerContent}>
        <BreadCrumb
          activePath={{ routeName: "Services" }}
          routingPaths={[{ path: "/", routeName: "Home" }]}
          title="Services"
        />
        <section className="section section-md pb-3">
          <ServicesList servicesLists={servicesLists} />
        </section>
      </Layout>
    );
  }
}
